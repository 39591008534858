import axios from 'axios';

import { loadingScreenOn, loadingScreenOff } from '../../components/js/loading-screen';
import { delayForLoadingScreen } from '../const';

const Toggles = ['send-report', 'send-reminder', 'global-cantab', 'mfa', 'auto-logout', 'anonymize-data'];

const ToggleHandlerInit = () => {
  Toggles.forEach((toggle) => {
    const toggleElement = document.querySelector(`#${toggle}`);

    if (toggleElement) {
      toggleElement.addEventListener('change', () => {
        loadingScreenOn();
        axios
          .get(toggleElement.dataset.link, { params: { toggle, value: toggleElement.checked } })
          .then(() => window.location.reload())
          .catch(() => {
            toggleElement.checked = !toggleElement.checked;
            setTimeout(loadingScreenOff, delayForLoadingScreen);
          });
      });
    }
  });
};

export default ToggleHandlerInit;
